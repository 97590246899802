#main-agente {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
}

#kit-digital {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 5vh;
  margin-bottom: 8vh;
}

.kit-digital-info {
  width: 400px;
  padding: 2vh 2vh;
  margin: 5px 5px;
  background: rgba(255, 255, 255, 0.55);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
}

.kit-digital-info-title {
  font-weight: 700;
  font-size: 23px;
  color: #dd0000;
}

.kit-digital-info-description {
  font-size: 15px;
}

.kit-digital-info-description-workers {
  margin: 0;
  padding: 0;
  font-size: 15px;
}

#kit-digital-titular {
  width: 100%;
  background-image: url("../../imgs/banner/company_.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: #dd0000;
  font-weight: 700;
  text-align: center;
}

.kit-digital-titular-text{
    margin: 0;
    padding: 20px 0;
}

#kit-digital-financiacion {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 5px;
  margin-top: 10px;
}

.kit-digital-financiacion-images{
/* display: flex; */
}

.europa {
  width: 50%!important;
}

.agents{
    max-width: 50vh!important;
}

.kit-digital-solutions {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.kit-digital-solution-header {
  width: 90%;
}

.kit-digital-solution {
  width: 400px;
  padding: 2vh 2vh;
  margin: 20px 20px;
  background: rgba(255, 255, 255, 0.55);
  border-radius: 8px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.kit-digital-element-header{
    color: #dd0000
}

.kit-digital-element-description{
    font-size: 15px;
}

.agente-form{
  width: 100%;
  background-image: url("../../imgs/banner/company_.jpg");
  background-position: center;
  background-size: cover;
  padding: 30px 30px;
}

@media (min-width: 600px){
  .kit-digital-info {
    width: 100%;
    margin: 20px;
  }
}

@media (min-width: 1116px){
  .kit-digital-info {
    width: 400px;
    margin: 20px;
  }

#kit-digital-titular{
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kit-digital-titular-text{
    font-size: 36px;
    text-align: center;
    
  }
}
