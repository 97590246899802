#desarrolladores {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-image: url("../../imgs/banner/landing.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#main {
  display: flex;
  background-image: url("../../imgs/banner/codeinvscode.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 1.3vh;
  font-weight: 700;
  width: 100%;
}

#first-text {
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: center;
  text-align: end;
  margin-right: 2vh;
}

.paragraph-one {
  font-size: 1.6vh;
}

.devs {
  font-size: 2vh;
  text-align: center;
}

.transformacion-digital {
  border-radius: 5vh;
  background-color: rgb(40, 0, 126);
}

#melenas {
  min-width: 40%;
  margin-top: 7vh;
  border-radius: 1vh;
  margin-left: 1vh;
  margin-right: 0;
}

#pelirroja {
  min-width: 40%;
  margin-bottom: 7vh;
  border-radius: 1vh;
  margin-left: 0;
  margin-right: 5vh;
  margin-top: 7vh;
}

.landing-container {
  margin-top: 200px;
}

#main-two {
  /* width: 98%; */
  padding: 2vh 2vh;
  margin: 5px 5px;
  background: rgba(255, 255, 255, 0.55);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
}

.necesidad-talento {
  width: 25%;
  color: black;
  text-align: center;
  align-self: center;
}

.dificultades {
  width: 20%;
  text-align: center;
  align-self: center;
}

.dificultades-span {
  font-size: 6vh;
  font-weight: bold;
}

.fotos-texto{
  display: flex;
  justify-content: space-around;
}

.texto-melenas {
  width: 40%;
  margin-top:9vh;
}

.que-necesitan{
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(2, 9, 216, 0.428);
  display: flex;
  flex-direction: column;
  padding-top: 5vh;
}

.que-necesitan-explicacion {
  display: flex;
  justify-content: space-around;
}

.que-necesitan-texto {
  width: 40%;
  align-self: center;
}

.p-img {
  width: 95%;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(2, 9, 216, 0.428);
  display: flex;
  justify-content: space-evenly;
  background-color: rgb(40,0,126);
  margin-bottom: 5vh;
}

.app {
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin: 3vh 0 3vh 3vh;
  align-self: center;
}

.app-strong {
  background-color: bisque;
  color: black;
}

.mobile-img {
  min-width: 50%;
  margin: 0;
  padding: 5vh;
  border-radius: 6vh;
}

.benefits-h2 {
  text-align: center;
}

.beneficios {
  width: 100%;
  margin-bottom: 5vh;
  margin-top: 5vh;
}

.benefits-cards {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  margin-top: 5vh;
}

.benefits-card {
  max-width: 23%;
}

.saber-mas {
  text-align: center;
  padding-top: 5vh;
}

.qnheader {
  text-align: center;
}

#main-three {
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../imgs/banner/handshake_extend.png");
  background-position: center;
  background-size: cover;
  height: 40vh;
}

#main-three-text {
  max-width: 90%;
}

#paragraph-three {
  width: 100%;
  text-align: end;
  margin-left: 0;
  font-size: 1.7vh;
  font-weight: 700;
}

#companies {
  font-size: 2vh;
}

#selection {
  font-size: 2vh;
}

#main-four {
  /* width: 98%; */
  padding: 2vh 2vh;
  margin: 5px 5px;
  background: rgba(255, 255, 255, 0.55);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
}

.companies-form {
  padding: 5vh;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
}

.companies-form-landing {
  padding: 5vh;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  margin-top: 5vh;
}

.cards {
  width: 90%;
  height: 90%;
  margin: 2vh 2vh;
  padding: 2vh 2vh;
}

.card {
  width: 98%;
  height: 98%;
  margin: 2vh 0;
  padding: 2vh 2vh;
  border-radius: 16px;
}

.card-paragraph {
  font-weight: 500;
}

.partner {
  font-weight: 500;
}

#preselection {
  font-size: 2vh;
}

#formulario{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slider {
  font-size: 2vh;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 70px;
}

.image {
  display: flex;
  align-items: center;
}

.slider-wrapper {
  position: absolute;
  display: flex;
}

.slider-section {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

img {
  width: 90%;
  max-width: 20vh;
  height: auto;
  margin: 0 3em;
  object-fit: cover;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

@media (max-width: 450px){
  .entrelineas {
    display: block!important;

  }

  .necesidad-talento {
    font-size: 18px;
    width: 90%;
    text-align: center;
  }
  
  .dificultades {
    width: 90%;
    font-size: 14px;
    text-align: center;
  }
  
  .dificultades-span {
    font-size: 3vh;
    font-weight: bold;
  }
  
  .fotos-texto{
    flex-direction: column;
    justify-content: space-around;
  }

  #melenas {
    min-width: 90%;
    align-self: center;
    margin-left: 0;
  }

  #pelirroja {
    min-width: 90%;
    align-self: center;
    margin-right: 0;
  }
  
  .texto-melenas {
    width: 100%;
    margin-top:9vh;
  }

  .qnheader {
    font-size: 24px;
  }
  
  .que-necesitan{
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(2, 9, 216, 0.428);
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
  }
  
  .que-necesitan-explicacion {
    padding: 3vh;
    display: block;
    justify-content: space-around;
  }
  
  .que-necesitan-texto {
    width: 90%;
    align-self: center;
  }
  
  .p-img {
    width: 95%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(2, 9, 216, 0.428);
    display: block;
    justify-content: space-evenly;
    background-color: rgb(40,0,126);
    margin-bottom: 5vh;
  }
  
  .app {
    color: white;
    font-weight: bold;
    font-size: 24px;
    margin: 3vh 0 3vh 1vh;
    align-self: center;
  }
  
  .app-strong {
    background-color: bisque;
    color: black;
  }
  
  .mobile-img {
    display: none;
    min-width: 50%;
    margin: 0;
    padding: 5vh;
    border-radius: 6vh;
  }
  
  .benefits-h2 {
    text-align: center;
  }
  
  .beneficios {
    width: 100%;
    margin-bottom: 0;
    margin-top: 4vh;
  }
  
  .benefits-cards {
    display: block;
    justify-content: space-around;
    font-size: 12px;
    margin-top: 5vh;
  }
  
  .benefits-card {
    max-width: 100%;
    margin: 5vh 0 5vh 0;
  }

  .benefits-header {
    color: black!important;

  }
  
  .saber-mas {
    text-align: center;
    padding-top: 5vh;
  }
}

@media (min-width: 451px) {
  .necesidad-talento {
    font-size: 18px;
  }
  
  .dificultades {
    width: 33%;
    font-size: 14px;
  }
  
  .dificultades-span {
    font-size: 3vh;
    font-weight: bold;
  }
  
  .fotos-texto{
    flex-direction: column;
    justify-content: space-around;
  }

  #melenas {
    min-width: 90%;
    align-self: center;
    margin-left: 0;
  }

  #pelirroja {
    min-width: 90%;
    align-self: center;
    margin-right: 0;
  }
  
  .texto-melenas {
    width: 100%;
    margin-top:9vh;
  }

  .qnheader {
    font-size: 24px;
  }
  
  .que-necesitan{
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(2, 9, 216, 0.428);
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
  }
  
  .que-necesitan-explicacion {
    padding: 3vh;
    display: block;
    justify-content: space-around;
  }
  
  .que-necesitan-texto {
    width: 90%;
    align-self: center;
  }
  
  .p-img {
    width: 95%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(2, 9, 216, 0.428);
    display: block;
    justify-content: space-evenly;
    background-color: rgb(40,0,126);
    margin-bottom: 5vh;
  }
  
  .app {
    color: white;
    font-weight: bold;
    font-size: 24px;
    margin: 3vh 0 3vh 1vh;
    align-self: center;
  }
  
  .app-strong {
    background-color: bisque;
    color: black;
  }
  
  .mobile-img {
    display: none;
    min-width: 50%;
    margin: 0;
    padding: 5vh;
    border-radius: 6vh;
  }
  
  .benefits-h2 {
    text-align: center;
  }
  
  .beneficios {
    width: 100%;
    margin-bottom: 0;
    margin-top: 4vh;
  }
  
  .benefits-cards {
    display: block;
    justify-content: space-around;
    font-size: 12px;
    margin-top: 5vh;
  }
  
  .benefits-card {
    max-width: 100%;
    margin: 5vh 0 5vh 0;
  }

  .benefits-header {
    color: black!important;

  }
  
  .saber-mas {
    text-align: center;
    padding-top: 5vh;
  }
  

  #main {
    display: flex;
    background-image: url("../../imgs/banner/codeinvscode.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  #first-text {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    height: 45vh;
    justify-content: center;
    text-align: end;
    width: 60%;
  }

  .devs {
    font-size: 2.4vh;
  }

  .slider {
    font-size: 2.4vh;
  }

  .paragraph-one {
    font-weight: 700;
    width: 90%;
    font-size: 1.5vh;
  }

  #background-image {
    height: 80vh;
    width: 50%;
  }

  #main-two {
    height: 30vh;
    padding: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #main-three {
    padding-right: 5vh;
    height: 50vh;
    display: flex;
    justify-content: flex-end;
  }

  #main-three-text {
    text-align: end;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  #paragraph-three {
    text-align: end;
    margin-left: 15vh;
    font-weight: 700;
    font-size: 1.6vh;
  }

  #main-four {
    /* margin: 5vh 10vh; */
    padding: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .card {
    width: 100%;
    height: auto;
    margin: 1vh 0;
    /* padding: 1vh; */
    border-radius: 1vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .card-paragraph{
    font-size: 1.6vh;
    font-weight: 700;
  }

  .inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 150px;
  }

  .image {
    display: flex;
    align-items: center;
  }

  .slider-wrapper {
    position: absolute;
    display: flex;
  }

  .slider-section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
  }

  img {
    width: 70%;
    max-width: 20vh;
    height: auto;
    margin: 0 5em;
    object-fit: cover;
  }
}

@media (min-width: 650px){
  .devs {
    font-size: 3vh;
  }
}

@media (min-width: 870px){

  .necesidad-talento {
    font-size: 24px;
  }
  
  .dificultades {
    width: 33%;
    font-size: 18px;
  }
  
  .dificultades-span {
    font-size: 3vh;
    font-weight: bold;
  }
  
  .fotos-texto{
    flex-direction: row;
    justify-content: space-around;
  }

  #melenas {
    min-width: 40%;
    align-self: center;
    margin-left: 1vh;
  }

  #pelirroja {
    min-width: 40%;
    align-self: center;
    margin-right: 1vh;
  }
  
  .texto-melenas {
    width: 40%;
    margin-top:9vh;
  }

  .qnheader {
    font-size: 32px;
  }
  
  .que-necesitan{
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(2, 9, 216, 0.428);
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
  }
  
  .que-necesitan-explicacion {
    padding: 3vh;
    display: flex;
    justify-content: space-around;
  }
  
  .que-necesitan-texto {
    width: 47%;
    align-self: center;
  }
  
  .p-img {
    width: 95%;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(2, 9, 216, 0.428);
    display: flex;
    justify-content: space-evenly;
    background-color: rgb(40,0,126);
    margin-bottom: 5vh;
  }
  
  .app {
    color: white;
    font-weight: bold;
    font-size: 24px;
    margin: 3vh 0 3vh 1vh;
    align-self: center;
  }
  
  .app-strong {
    background-color: bisque;
    color: black;
  }
  
  .mobile-img {
    display: block;
    min-width: 50%;
    margin: 0;
    padding: 5vh;
    border-radius: 6vh;
  }
  
  .benefits-h2 {
    text-align: center;
  }
  
  .beneficios {
    width: 100%;
    margin-bottom: 0;
    margin-top: 4vh;
  }
  
  .benefits-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    font-size: 12px;
    margin-top: 5vh;
  }
  
  .benefits-card {
    max-width: 45%;
    min-width: 45%;
    margin: 5vh 0 5vh 0;
  }

  .benefits-header {
    color: black!important;

  }
  
  .saber-mas {
    text-align: center;
    padding-top: 5vh;
  }
  

    .devs {
        font-size: 3.5vh;
    }

    .slider {
      font-size: 3.5vh;
    }

    #first-text {
        height: 70vh;
    }

    #main-three {
        height: 70vh;
    }
}

@media(min-width: 1050px) {
  .paragraph-one {
    font-size: 2.2vh;
  }

  .slider {
    text-align: center;
  }
  
  .devs {
    text-align: end;
  }

  #main-two {
    margin: 7vh;
  }

  #main-four {
    margin: 7vh;
  }
  
  #first-text {
    width: 40%;
  }

  #main-three-text{
    width: 40%;
  }
  
  .card {
    width: 28%;
    margin: 3vh;
  }
}

@media(min-width: 1400px) {
 
  .benefits-card {
    max-width: 24%;
    min-width: 24%;
    margin: 5vh 0 5vh 0;
  }
}