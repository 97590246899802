p {
  font-size: 1.2em;
}

h2 {
  color: #dd0000;
}

h3 {
  color: #dd0000;
}
h4 {
  color: #dd0000;
}
h5 {
  color: #dd0000;
}
h6 {
  color: #dd0000;
}


.explanation {
  text-align: center;
  font-size: 2vh;
}

#precurso{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  text-align: center;
  background-image: url("../imgs/annieTwo.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 50vh;
  max-width: 100%;
}

#precurso-p{
  color: white;
}

#precurso-h2{
  margin-top: 0;
  color: white;
}

#precurso-h4{
  color: white;
}

.humo {
  background: linear-gradient(to left, rgba(234, 54, 54, 0.8), rgba(234, 54, 54, 0.8)), url("../imgs/banner/landing.png") no-repeat center center scroll;
  background-size: cover;
  background-position-y: -100px;
  background-repeat: no-repeat;
  margin: 0 !important;
  flex-direction: column;
}

.onboarding {
  background-image: linear-gradient(to left, rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("../imgs/banner/onboarding.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-position-y: -100px; */
  margin: 0 !important;
  flex-direction: column;
}

.developers{
  background-image: linear-gradient(to left, rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("../imgs/banner/dev.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-position-y: -100px; */
  margin: 0 !important;
  flex-direction: column;
}



.agente-digitalizador {
  background-image: linear-gradient(to left, rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("../imgs/banner/digital-agency.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-position-y: -100px; */
  margin: 0 !important;
  flex-direction: column;
}

.landingImage {
  margin-top: 40vh;
  /* margin-bottom: 10vh; */
  padding: 0 5vh 5vh 5vh;
  border-radius: 5px;
}

.banner-card {
  width: 28%;
  margin: 2vh;
  padding: 2vh;
  background-color: rgba(255, 255, 255, 0.7) !important;
  text-align: center !important;
}

.banner-row {
  justify-content: center;
  padding-bottom: 6vh;
}

#onboarding {
  text-align: center;
  background-image: url("../imgs/banner/handshake_extend.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

@media (max-width: 991px) {
  .banner-card {
    width: 70%;
  }
}

#landingLogo {
  max-width: 90%;
  width: 90%;
}

#title {
  text-align: center;
  padding-bottom: 10vh;
  margin-bottom: 10vh;
}

.cards-landing {
  height: 70%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 6vh;
}

.remoto {
  background: url("../imgs/banner/remoto.png") no-repeat center center scroll;
  background-size: cover;
  background-position-y: -100px;
  background-repeat: no-repeat;
}

.presencial {
  background: url("../imgs/banner/presencial.png") no-repeat center center
    scroll;
  background-size: cover;
  background-position-y: -100px;
  background-repeat: no-repeat;
}

.textoDymo {
  background-color: black;
  color: white;
  display: inline;
}
.textoDymo2 {
  text-align: left;
  background-color: black;
  color: white;
  display: inline;
  text-transform: uppercase;
}
.textoDymoLight {
  text-align: left;
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
  display: inline;
}

a {
  color: #dd0000;
}

#btc-pres {
  color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
}

a:hover {
  color: #8d0000;
  text-decoration: none;
}

ul {
  list-style: none; /* Remove default bullets */
  font-size: 1.2em;
}

ul li::before {
  content: "\25cf";
  color: #dd0000;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.logorrss {
  font-size: 3em;
  text-decoration: none;
  color: black;
}

.logorrss:hover {
  color: #dd0000;
}

.bloqueExplicacion {
  margin-top: 40px;
  text-align: justify;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

@media (max-width: 991px) {
  #btc-pres {
    color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem;
    padding-left: 0;
  }
}

body{
  background-color: white!important;
}
p {
  font-size: 2vh;
}

h2 {
  color: #dd0000;
  margin: 1em 0 1em;
  text-align: center;
}

h3 {
  color: #dd0000;
}
h4 {
  color: #dd0000;
}
h5 {
  color: #dd0000;
}
h6 {
  color: #dd0000;
}

.explanation {
  text-align: center;
  font-size: 2vh;
}

/* .financial-div {
  height: 100px;
} */

.presencial {
  background: url("../imgs/banner/presencial.png") no-repeat center center
    scroll;
  background-size: cover;
  background-position-y: -100px;
  background-repeat: no-repeat;
}

.textoDymo {
  background-color: black;
  color: white;
  display: inline;
}

.textoDymo2 {
  text-align: left;
  background-color: black;
  color: white;
  display: inline;
  text-transform: uppercase;
}
.textoDymoLight {
  text-align: left;
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
  display: inline;
}

.p {
  text-align: left;
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
  display: inline;
  width: 99%;
}

a {
  color: #dd0000;
}

a:hover {
  color: #dd0000;
  text-decoration: none;
}

ul {
  list-style: none; /* Remove default bullets */
  font-size: 1.2em;
}

ul li::before {
  content: "\25cf";
  color: #dd0000;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.logorrss {
  font-size: 3em;
  text-decoration: none;
  color: black;
}

.logorrss:hover {
  color: #dd0000;
}

.bloqueExplicacion {
  margin-top: 40px;
  text-align: justify;
}

.teamCards{
  justify-content: center;
}

.mentorCards .mentorCard{
  width: 330px;
}

#close-icon{
  display: flex;
  justify-content: flex-end;
}

#close-img {
  width:4%;
}

#card-list {
  display: flex;
  justify-content:space-around;
  flex-wrap: wrap;
  margin-bottom: 3vh;
}

.card {
  max-width: 40%;
  padding: 2vh;
  margin: 2vh;
  border-radius: 1vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-bullet {
  max-width: 70%;
  width: 70%;
  padding: 1vh;
  margin: 1vh;
  border-radius: 1vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card p {
  text-align: center;
}

.header {
  display: flex;
  justify-content:space-between;
}

.checked {
  color: orange;
}

#rating {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#rating-note {
  margin-top: 1vh;
}

#reviews-button{
  padding: 2vh;
  background-color: #dd0000;
  color: white;
  font-size: 2vh;
  border-radius: 1vh;
  margin: 2vh;
  border: 1px solid white;
  transition: background-color 0.5s;
  transition: color 0.5s;
  transition: border 0.5s;
}

#reviews-button:hover{
  background-color: white;
  color:#dd0000;
  border: 1px solid #dd0000;
}

#reviews-button-div{
  display:flex;
  justify-content:center;
}

#precurso{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  text-align: center;
  background-image: url("../imgs/annieTwo.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 50vh;
}

#precurso-p{
  color: white;
}

#precurso-h2{
  margin-top: 0;
  color: white;
}

#precurso-h4{
  color: white;
}

#becas {
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.finance {
  font-size: 3vh;
}

.financial-img{
  max-width: 70%;
}

.financial-col{
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
}

.financial-container{
  margin-top: 0!important;
}

@media (min-width:768px) and (max-width: 1500px){
  #card-list {
    display: flex;
    flex-wrap: wrap;
  }

  .explanation {
    text-align: center;
    font-size: 3vh;
  }

  .card{
    max-width: 45%;
    margin: 1vh;
  }

  .finance {
    font-size: 3vh;
  }
}

@media (max-width: 990px){
  .explanation {
    text-align: center;
    font-size: 3vh!important;
  }

  .finance {
    font-size: 3vh!important;
  }

  .financial-img{
    max-width: 100%;
  }

  .financial-options-row{
    display: flex;
    flex-direction: column;
  }

  .financial-container {
    margin-top: 5vh!important;
  }

}

@media (max-width: 767px){

  #card-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .explanation {
    text-align: center;
    font-size: 4vh;
  }

  .card {
    max-width: 80%;
    margin: 2vh;
  }

  .mobileNot--vh-100{
    height: initial!important;
  }
  #dropdown-basic-button{
    margin-top: 0.5em;
  }

  .MuiInputBase-input {
    height: 1.7876em;
  }
}
@media (max-width: 650px){
  .inputSmall--mobile .MuiInputLabel-outlined{
    font-size: 0.8rem;
  }
}
@media (max-width: 550px){
  .inputSmall--mobile .MuiInputLabel-outlined{
    font-size: 0.6rem;
  }
  /* .explanation{
    font-size: 3vh
  } */
  #precurso {
    font-size: 2vh!important;
  }

  #precurso-p{
    font-size: 2vh!important;
  }

}
